<template>
    <div class="character_details payment_page">
        <Top  name="商品购买" email back></Top>
        <div class="index_list">
            <img class="profilePhoto" :src="detailData.imgSrc">
            <span class="index_list_c">
                <div class="index_list_c_t" v-if="goods_type == 1">{{detailData.channel_name}}\{{detailData.job}}\{{detailData.lv}}级</div>
                <div class="index_list_c_t" v-else-if="goods_type == 3">{{detailData.channel_name}}\{{detailData.nickname}}</div>
                <div class="index_list_c_t" v-else>{{detailData.channel_name}}\{{detailData.nickname}}×{{detailData.currencyNum}}</div>
                <div class="index_list_c_c">{{detailData.server_name}}</div>
                <div class="index_list_c_b">￥{{detailData.price}}</div>
            </span>
            <span class="index_list_r">
                <img class="authentication_img" src="../../assets/images/authentication.png">
            </span>
        </div>
        <ul class="light">
            <li class="top">合计</li>
            <li class="top orange">￥{{detailData.price}}元</li>
            <li class="bottom"><van-checkbox v-model="checked" @change="peyChange">使用钱包余额抵扣</van-checkbox></li>
            <li class="bottom tips">
                <span>￥{{accountBalance}}</span>&ensp;
                <van-popover v-model="showPopover" get-container="#payment_page_icon" placement="bottom-end" trigger="click">
                    <div style="background-color: #4a4a4a;" class="payment_page_pop_wrap">
                        <p class="balance"><span>可提现余额：</span><span>{{accountBalance}}元</span></p>
                        <p class="balance"><span>考察期余额：</span><span>{{frozenBalance}}元</span></p>
                        <p class="reminder">提示：考察期余额24小时后</p>
                        <p class="reminder">方可提现、购买角色</p>
                    </div>
                    <template #reference>
                        <van-icon size="25" id="payment_page_icon" name="question-o" />
                    </template>
                </van-popover>
            </li>
            <p class="orange" style="font-size: .8125rem;text-align: left;    margin-top: 0.5rem;">沙巴克城主套以及排行榜数据为玩家上架时的情况，非实时刷新，购买前请谨慎核实</p>
        </ul>
        <div class="price_tag">
            <span class="price_tag_word">仍需支付：</span>
            <span class="orange">￥{{paymoney}}</span>
            <van-button :disabled="loading" @click="payClick" class="purchase_btn" round color="linear-gradient(to right, rgb(255, 112, 77), rgb(247, 55, 39))"  size="large">立即支付</van-button>
        </div>
        <van-popup v-model="input_show" class="password_border">
            <div class="password_input" style="width: 19.15625rem;">
                <div style="height:0" class="close"><van-icon  @click="input_show=false" name="cross" /></div>
                <div class="password_text">请输入支付密码</div>
                <div class="password_sum">￥{{detailData.price}} </div>
                <div class="password_deduction">余额抵扣</div>
                <van-password-input
                    :value="password"
                    :focused="showKeyboard"
                    @focus="showKeyboard = true"
                />
                <router-link :to="{ name: 'Passwordset', params: { active: 1}}"><div class="password_forget">忘记密码</div></router-link>
                
            </div>
        </van-popup>
        <van-number-keyboard
                    v-model="password"
                    :show="showKeyboard"
                    :maxlength="6"
                    @blur="showKeyboard = false"
                />
    </div>
</template>
<script>
import Top from '@/components/top'
export default {
    name:"paymentPage",
    data(){
        return {
            value:'',
            checked:false,
            showPopover:false,
            input_show:false,
            password: '',
            showKeyboard: false,
            detailData: {
                channel_name: '',
                job: '',
                lv: '',
                server_name: '',
                server_id: '',
                price: '',
                imgSrc: '',
                goods_id: '',
                nickname: '',
                seedname: '',
                status_name: '',
                surplus_time: '',
                currencyNum: 0,
                item_id: ''
            },
            accountBalance: 0,
            frozenBalance: 0,
            params: {},
            paymoney: '',
            loading:true,
            goods_type: 1,
        }
    },
    components: {
      Top
    },
    created() {
        //获取路由参数
        this.params = this.$route.query;
        //获取详情数据
        this.getDetailData();
        //获取账户余额
        this.getBalance();
        //清空订单请求判断
        localStorage.removeItem('isPay');
    },
    watch: {
        password(newVal, oldVal) {
            //console.log(newVal, oldVal);
            let isbalance = this.checked ? 1 : 0;
            if (newVal.length == 6) {
                this.showKeyboard = false;
                this.$toast({
                    duration: 1500,
                    message: '支付中······'
                });
                this.$api.shopping.checkPayPassword({
                    payPw: newVal
                }).then(res => {
                    if (res.code == 200) {
                        this.paySubmit();
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    },
    methods:{
        //获取详情数据
        getDetailData() {
            this.$api.shopping.goodsDetails({
                goods_id: this.params.goods_id,
            }).then(res => {
                if (res.code == 200) {
                    this.loading = false;
                    this.goods_type = res.data.goods_type;
                    this.detailData = res.data.goods_detal;
                    //计算抵扣后需要支付的金额
                    this.getMoney();

                    //获取头像
                    if(res.data.goods_type != 1){
                        //货币、装备
                        try{
                            this.detailData.imgSrc = require('../../assets/images/itemicon/' + this.params.channel_id + '/' + res.data.goods_detal.icon_id + '.png');
                        }catch(e){
                            this.detailData.imgSrc = require('../../assets/images/question.png');
                        }
                    } else {
                        //获取头像 角色
                        if (this.detailData.sex == 200) {
                            switch(this.detailData.job) {
                                case '战士':
                                    this.detailData.imgSrc = require('../../assets/images/photos/zs-nan@2x.png');
                                    break;
                                case '法师':
                                    this.detailData.imgSrc = require('../../assets/images/photos/fa-nan@2x.png');
                                    break;
                                default:
                                    this.detailData.imgSrc = require('../../assets/images/photos/ds-nan@2x.png');
                            }
                        }else{
                            switch(this.detailData.job) {
                                case '战士':
                                    this.detailData.imgSrc = require('../../assets/images/photos/zs-nv@2x.png');
                                    break;
                                case '法师':
                                    this.detailData.imgSrc = require('../../assets/images/photos/fa-nv@2x.png');
                                    break;
                                default:
                                    this.detailData.imgSrc = require('../../assets/images/photos/ds-nv@2x.png');
                            }
                        }
                    }

                    if (res.data.goods_type == 2) {
                        //获取货币数量
                        this.detailData.currencyNum = res.data.goods_menu;
                    }

                }
            }).catch(err => {
                console.log(err);
            });
        },
        //获取账户余额
        getBalance() {
            let username = localStorage.getItem('username');
            this.$api.account.getBalance({username:username}).then((res)=>{
                if (res.code == 200) {
                    this.accountBalance = res.data.accountBalance;
                    this.frozenBalance = res.data.frozenBalance;
                    //计算抵扣后需要支付的金额
                    this.getMoney();
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //计算需要支付的金额
        getMoney() {
            //开启抵扣
            if (this.checked) {
                //判断余额是否够用
                this.$api.shopping.ableAllPay({
                    goods_id: this.params.goods_id,
                }).then(res => {
                    if (res.code == 200) {
                        //alert(res.data.able_all_balance_pay);
                        if (res.data.able_all_balance_pay == 1) {
                            this.paymoney = 0;
                        }else{
                            // this.paymoney = this.detailData.price;
                            // this.checked = false;
                            this.paymoney = this.accSub(this.detailData.price, this.accountBalance);
                        }
                    }
                }).catch(err => {
                    console.log(err);
                });
                // if (this.detailData.price > this.accountBalance) {
                //     this.paymoney = this.accSub(this.detailData.price, this.accountBalance);
                // }else{
                //     this.paymoney = 0;
                // }
            }else{
                this.paymoney = this.detailData.price;
            }
        },
        //是否抵扣余额切换
        peyChange() {
            //console.log(val, this.checked);
            // if (this.checked && this.detailData.price > this.accountBalance) {
            //     this.checked = false;
            //     this.$toast({
            //         duration: 1500,
            //         message: '余额不足，请选择其他付款方式'
            //     });
            // } else {
                this.getMoney();
            // }
        },
        //立即支付
        payClick() {
            if (this.paymoney == 0) {
                this.input_show = true;
                this.password = '';
            }else{
                let checked = this.checked ? 1 : 2, data = {
                    goods_id: this.detailData.goods_id,
                    number: this.paymoney,
                    checked: checked,
                }, dataObj = {};
                if (this.params.to_server) {
                    dataObj = data;
                    dataObj.to_server = this.params.to_server;
                    dataObj.chrname = this.params.chrname;
                } else {
                    dataObj = data;
                }

                this.$router.push({
                    name: 'paymentChannels',
                    query: dataObj
                });
            }
        },
        //精确计算小数减法结果
        accSub(arg1, arg2) {
            if (isNaN(arg1)) {
                arg1 = 0;
            }
            if (isNaN(arg2)) {
                arg2 = 0;
            }
            arg1 = Number(arg1);
            arg2 = Number(arg2);

            var r1, r2, m, n;
            try {
                r1 = arg1.toString().split(".")[1].length;
            }
            catch (e) {
                r1 = 0;
            }
            try {
                r2 = arg2.toString().split(".")[1].length;
            }
            catch (e) {
                r2 = 0;
            }
            m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
            n = (r1 >= r2) ? r1 : r2;
            return ((arg1 * m - arg2 * m) / m).toFixed(n);
        },
        //调起支付
        paySubmit() {
            let isbalance = this.checked ? 1 : 0, 
            data = {
                username: localStorage.getItem('username'),
                goods_id: this.params.goods_id,
                pay_type: 'ali',
                type: 'wap',
                is_balance: isbalance
            }, dataObj = {};
            if (this.params.to_server) {
                dataObj = data;
                dataObj.to_server = this.params.to_server;
                dataObj.chrname = this.params.chrname;
            } else {
                dataObj = data;
            }
            this.$api.shopping.createOrderAli(dataObj).then(res => {
                if (res.code == 200) {
                    //this.$toast.success('支付成功');
                    this.input_show = false;
                    this.$router.push({
                        name: 'Paysuccess',
                    });
                }
            }).catch(err => {
                console.log(err);
            });
        },
    }
    
}
</script>
<style scoped>
.character_details{
    background:#ececec;
    height: 100%;
}
.character_details .index_list{
    display: flex;
    padding: 1.1875rem 1.3125rem;
    border-bottom: 1px solid #bbbbbb;
    background: #fff;
}
.character_details .index_list .index_list_c{
    width: 57%;
    text-align: left;
    padding-left: .40625rem;
}
.character_details .index_list .index_list_c .index_list_c_t{
    font-size: .875rem/1.125rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #313131;
    padding-bottom: .21875rem;
}
.character_details .index_list .index_list_c .index_list_c_c{
    font-size: .75rem/1.15625rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #707070;
    padding-bottom: .21875rem;
}
.character_details .index_list .index_list_c .index_list_c_b{
    font-size: .875rem/1.0625rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #ff4e00;
}
.character_details .index_list .profilePhoto{
    width: 3.75rem;
    height: 3.75rem;
}
.character_details .index_list .authentication_img{
    width: 5.125rem;
    height: 1.65625rem;
}
.orange{
    color: #ff4e00 !important;
}
.character_details .price_tag{
    width: 100%;
    height: 3.125rem;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    text-align: right;
    padding-top: .5rem;
}
.price_tag .purchase_btn{
    width: 5.625rem;
    height: 2.125rem;
    vertical-align: middle;
    margin: 0 1.34375rem;
}
.price_tag .orange{
    font: .875rem/2.125rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    display: inline-block;
    vertical-align: middle;
}
.price_tag .price_tag_word{
    font: .71875rem/2.125rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #313131;
    vertical-align: middle;
}




.payment_page .light{
    display: flex;
    flex-wrap: wrap;
    padding: 0 1.3125rem;
}
.payment_page .light li{
    width: 50%;
}
.payment_page .light .top{
    font: .875rem/2.40625rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
}
.payment_page .light .bottom{
    font-size: .8125rem;
    line-height: 1.875rem !important;
    color: #707070;
}
.payment_page .light .tips span{
    vertical-align: middle;
}
.payment_page .light .tips i{
    vertical-align: middle;
    color: #333132;
}
.payment_page .light li:nth-child(odd){
    text-align: left;
}
.payment_page .light li:nth-child(even){
    text-align: right;
}
.payment_page_pop_wrap{
    padding: 1rem;
}
.payment_page_pop_wrap  p {
    font: .8rem/1.2rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    white-space:nowrap;
}
.payment_page_pop_wrap .balance{
    display: flex;
    justify-content: space-between;
    color: #fff;
}
.payment_page_pop_wrap .reminder{
    text-align: left;
    color: #fff;
    
}
.van-number-keyboard {
    z-index: 200004;
}
.password_input .close{
    text-align: right;
    font: .875rem/1.3125rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    padding-top: .3125rem;
}
.password_input .close i{
    margin-right: .3375rem;
}
.password_border{
    border-radius: .84375rem;
    background: #efefef;
}
.password_input .password_text{
    font: .9375rem /1.875rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #313131;
    border-bottom: 1px solid #b3b3b3;
}
.password_input .password_sum{
    font: 900 1.34375rem/1.84375rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    margin-top: .9375rem;
}
.password_input .password_deduction{
    font: .71875rem/1.21875rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    padding-bottom: .71875rem;
    border-bottom: 1px dashed #b3b3b3;
    margin-bottom: .96875rem;
}
.van-password-input__security li{
    background: #b3b3b3;
}
.password_input .password_forget{
    font: .98125rem/2.71875rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #227eeb;
    text-align: right;
    padding-right: 1rem;
}
</style>
<style>
    .payment_page .light .bottom .van-checkbox__label{
        line-height: 1.875rem !important;
        color: #707070;
    }
    .payment_page .light .van-popover__arrow{
        display: none;
    }
</style>